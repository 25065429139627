import React, { useCallback, useEffect, useMemo, useState } from 'react';

import UI from 'component/ui';
import FilterForm from 'component/form/filter-form';
import { useRegistry } from 'component/form';
import Loader from 'component/loader';
import { useClassnames } from 'hook/use-classnames';

import style from './styles.pcss';
import useIntersect from 'hook/use-intersect';
import debounce from 'lodash.debounce';
import history from 'component/core/history';
import { parse, stringify } from 'query-string';
import { normalizeObject } from 'component/helper/normalize-object';
import { INormalizeObject } from 'component/helper/types/normalize-object';
import { Page } from 'src/api/base';
import api from 'src/api';
import NewPhotoList from 'component/photo-list/new-photo-list';
import { GetLatestPhotosListData, LatestPhotoItem } from 'src/api/latest-photos/types';

const PHOTOS_LIMIT = 100;

const getNormalizedQuery = () => {
    const qs = parse(location.search);

    return normalizeObject(qs);
};

const PersonPhotos = () => {
    const cn = useClassnames(style);

    const [queryParams, setQueryParams] = useState<INormalizeObject>(getNormalizedQuery());

    // Sidebar
    const registry = useRegistry();
    const [validity, setValidity] = useState<boolean>(false);

    // Content
    const [photosList, setPhotosList] = useState<Array<LatestPhotoItem>>([]);
    const [photosTotal, setPhotosTotal] = useState<number>(0);
    const [photosPage, setPhotosPage] = useState<number>(1);
    const [isPhotosLoading, setIsPhotosLoading] = useState<boolean>(false);
    const [isPhotosLoadMore, setIsPhotosLoadMore] = useState<boolean>(false);
    const [isPhotosNext, setIsPhotosNext] = useState<boolean>(false);

    useEffect(() => {
        if (isPhotosLoading || isPhotosLoadMore) {
            const page: Page = {
                pageNumber: photosPage,
                pageSize: PHOTOS_LIMIT
            };

            const filter: GetLatestPhotosListData = {
                person_id: queryParams.person_id,
                is_person_find_partner: queryParams.is_person_find_partner,
                is_stock: queryParams.is_stock,
                photographer_id: queryParams.photographer_id,
                color_id: queryParams.color_id,
                number: queryParams.number,
                time_after: queryParams.time_from,
                time_before: queryParams.time_to,
                search: queryParams.search,
                location_id: queryParams.location_id,
                date: queryParams.date,
                price_min: queryParams.price_from,
                price_max: queryParams.price_to
            };

            api.latestPhotos.getLatestPhotosList(page, filter)
                .then((resp) => {
                    setPhotosList(isPhotosLoadMore ?
                        [...photosList, ...resp.data.results]
                        : resp.data.results);

                    setPhotosTotal(resp.data.count);
                    setIsPhotosNext(!!resp.data.next);
                    setIsPhotosLoading(false);
                    setIsPhotosLoadMore(false);
                })
                .catch(() => {
                    setIsPhotosLoading(false);
                    setIsPhotosLoadMore(false);
                    setPhotosList([]);
                });
        }
    }, [isPhotosLoading, isPhotosLoadMore]);

    useEffect(() => {
        if(registry.form.checkValidity() && !isPhotosLoading && !isPhotosLoadMore) {
            setPhotosPage(1);
            setIsPhotosLoading(true);
        }
    }, [JSON.stringify(queryParams)]);

    useEffect(() => {
        setQueryParams(getNormalizedQuery());
    }, [location.search]);

    const $LoadMorePhotos = useIntersect((entry) => {
        if(entry.isIntersecting) {
            setPhotosPage((prev) => prev + 1);
            setIsPhotosLoadMore(true);
        }
    }, {
        rootMargin: '500px 0px'
    });

    // Content elements
    const elLoadMorePhotos = () => {
        if (!isPhotosLoading && !isPhotosLoadMore && isPhotosNext && photosList.length) {
            if (photosTotal > photosList.length) {
                return <Loader ref={$LoadMorePhotos} />;
            }
        }
    };

    const elPhotos = useMemo(() => {
        if (photosList.length === 0) {
            return <span className={cn('person-photo__empty')}>По Вашему запросу не удалось найти фото</span>;
        }

        if (photosList.length) {
            const link = '/persons/photos';

            return (
                <NewPhotoList
                    elIcons={false}
                    elControls={false}
                    elPortal={false}
                    link={link}
                    list={photosList}
                    query={location.search}
                    is_prepaid={false}
                />
            );
        }
    }, [JSON.stringify(photosList)]);

    const elContent = useMemo(() => {
        if (isPhotosLoading) {
            return <Loader />;
        }

        return (
            <UI.Box padding={true} className={cn('person-photo__box')}>
                {elPhotos}
                {elLoadMorePhotos()}
            </UI.Box>
        );
    }, [isPhotosLoading, isPhotosLoadMore, JSON.stringify(photosList)]);

    // Forms functions
    const onChangeForm = debounce(useCallback(() => {
        const payload = registry.form.getPayload();

        const data = {
            ...(payload.name && { search: payload.name }),
            ...(payload.location && { location_id: payload.location.value }),
            ...(payload.location && { location_name: payload.location.label }),
            ...(payload.event && { event_id: payload.event.value }),
            ...(payload.club?.value && { club_id: payload.club.value }),
            ...(payload.club?.label && { club_name: payload.club.label }),
            // ...(payload.photo_date_range?.date_from && { date_from: payload.photo_date_range.date_from }),
            // ...(payload.photo_date_range?.date_to && { date_to: payload.photo_date_range.date_to }),
            ...(payload.calendar_date && { date: payload.calendar_date }),
            ...(payload.price_range?.value_from && { price_from: payload.price_range.value_from }),
            ...(payload.price_range?.value_to && { price_to: payload.price_range.value_to }),
            ...(payload.color && { color_id: payload.color }),
            ...(payload.sport_number && { number: payload.sport_number }),
            ...(payload.time && { time: payload.time }),
            ...(payload.partner_search && { only_find_partner_participant: payload.partner_search }),
            ...(payload.suit_sale && { suit_sale: payload.suit_sale }),
            ...(payload.timeRange?.valueFrom && { time_from: payload.timeRange.valueFrom }),
            ...(payload.timeRange?.valueTo && { time_to: payload.timeRange.valueTo }),
            ...(queryParams.person_id && { person_id: queryParams.person_id }),
            ...(queryParams.photographer_id && { photographer_id: queryParams.photographer_id })
        };

        history.replace({
            search: stringify(data, {
                arrayFormat: 'none'
            }),
            state: {
                noScroll: true
            }
        });
    }, [
        validity,
        JSON.stringify(registry.form.getPayload())
    ]), 300);

    return (
        <UI.Main className={cn('person-photo')}>
            {/*<UI.PageHeader text={'Фото с Андрей Рублёв'} />*/}
            <div className={cn('person-photo__main')}>
                <UI.Content className={cn('person-photo__content')}>
                    {elContent}
                </UI.Content>
                <UI.Sidebar>
                    <FilterForm
                        registry={registry}
                        name={true}
                        color={true}
                        calendarRange={true}
                        personNumber={true}
                        location={true}
                        event={true}
                        price={true}
                        onChange={onChangeForm}
                        onChangeValidity={setValidity}
                        isOnlyRecentDays={true}
                    />
                </UI.Sidebar>
            </div>
        </UI.Main>
    );
};

export default PersonPhotos;
