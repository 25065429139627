import React, { useEffect, useState } from 'react';
import Calendar from 'react-calendar';
import moment from 'moment';
// tslint:disable-next-line:no-submodule-imports
import 'react-calendar/dist/Calendar.css';
import './index.css';
import { IReturnField } from '../types/registry';

interface IProps {
    registry: IReturnField;
    name: string;
    error?: TError;
    defaultDate?: Date;
}

type TError = string | null;
const DateRangeCalendar = (props: IProps) => {
    const [selectedDates, setSelectedDates] = useState<[Date, Date] | null>(
        props.defaultDate ? [new Date(moment(props.defaultDate).subtract(6, 'days').toDate()), props.defaultDate] : null
    );
    const [errorExternal, setErrorExternal] = useState<TError>(props.error || null);
    const [showTooltip, setShowTooltip] = useState<boolean>(true);

    const onDateChange = (value: Date | [Date, Date] | null) => {
        if (!value) { return; }

        const dates = Array.isArray(value) ? value : [moment(value).subtract(6, 'days').toDate(), value];
        const [start, end] = dates;

        setSelectedDates([moment(end).subtract(6, 'days').toDate(), end]);
    };

    useEffect(() => () => {
        props.registry.remove(props.name);
    }, []);

    useEffect(() => {
        props.registry.set(props.name, {
            setError: setErrorExternal,
            clear   : () => {
                setSelectedDates(null);
            },
            value: selectedDates ? moment(selectedDates[1]).format('YYYY-MM-DD') : null,
            isValid: true,
            isAutoFill: false
        });
    }, [selectedDates]);

    useEffect(() => {
        const handler = props.registry.onChange();

        if(handler) {
            handler();
        }
    }, [selectedDates]);

    useEffect(() => {
        const timer = setTimeout(() => {
            setShowTooltip(false);
        }, 5000);

        return () => clearTimeout(timer);
    }, []);

    const handleMouseEnter = () => {
        setShowTooltip(true);
    };

    const handleMouseLeave = () => {
        setShowTooltip(false);
    };

    return (
        <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            <div className={`tooltip ${showTooltip ? 'show' : ''}`}>
                Максимальный диапазон дат - 7 дней
            </div>
            <Calendar
                className={`${props.defaultDate ? '' : 'calendar__empty-value'}`}
                onChange={(value) => onDateChange(value as Date | [Date, Date] | null)}
                value={selectedDates ? selectedDates : undefined}
                defaultValue={selectedDates}
                locale="ru-RU"
            />
        </div>
    );
};

export default DateRangeCalendar;
