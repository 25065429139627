import React, { useMemo, useState, useEffect, useCallback, Fragment, MouseEvent } from 'react';

import useIntersect from 'hook/use-intersect';
import { useClassnames } from 'hook/use-classnames';
import UI from 'component/ui';
import Loader from 'component/loader';
import api from 'src/api';
import { Event, EventsSearchFilter } from 'src/api/events/types';
import Result from 'component/search-result';

import style from './index.pcss';
import SearchForm from 'route/search/search-form';
import { useRegistry } from 'component/form';
import debounce from 'lodash.debounce';
import history from 'component/core/history';
import { parse, stringify } from 'query-string';
import { INormalizeObject } from 'component/helper/types/normalize-object';
import { normalizeObject } from 'component/helper/normalize-object';
import { PersonItem, PersonsSearchFilter } from 'src/api/persons/types';
import PersonCarousel from 'component/person-carousel';
import CarouselItem from 'component/person-carousel/item';
import { useSelector } from 'react-redux';
import { IStore } from 'store/reducers/types/reducers';
import { key as keyDeviceInfo } from 'store/reducers/deviceInfo/reducer';
import { Page } from 'src/api/base';
import { CreateTempFile } from 'src/api/files/types';
import { Photo, PhotosSearchFilter } from 'src/api/photos/types';

const EVENTS_LIMIT = 12;
const getNormalizedQuery = () => {
    return normalizeObject(parse(location.search));
};

const Search = () => {
    const cn = useClassnames(style);
    const registry = useRegistry();

    const isMobile = useSelector<IStore, boolean>((store) => store[keyDeviceInfo].mobile);
    const isTablet = useSelector<IStore, boolean>((store) => store[keyDeviceInfo].tablet);

    const [ isEventListPending, setIsEventListPending ] = useState(false);
    const [ isResetEvents, setIsResetEvents ] = useState<boolean>(false);
    const [ isNextEventPage, setIsNextEventPage ] = useState(false);
    const [ isEventListMerge, setIsEventListMerge ] = useState(false);
    const [ eventList, setEventList ] = useState<Array<Event>>([]);
    const [ eventPage, setEventPage ] = useState(1);
    const [ queryParams, setQueryParams ] = useState<INormalizeObject>(getNormalizedQuery());
    const [ chosenPersonID, setChosenPersonID ] = useState<number | null>(null);
    const [ imageID, setImageID ] = useState<string | null>(null);
    const [ isPersonsPending, setIsPersonsPending ] = useState(false);
    const [ isPersonsMerge, setIsPersonsMerge ] = useState(false);
    const [ isPersonsNext, setIsPersonsNext ] = useState(false);
    const [ persons, setPersons ] = useState<Array<PersonItem>>([]);
    const [ personsPage, setPersonsPage ] = useState(1);

    // latest photos
    const [totalLatestPhotos, setTotalLatestPhotos] = useState<number>(0);
    const [latestPhotosList, setLatestPhotosList] = useState<Array<Photo>>([]);
    const [isLatestPhotosLoading, setIsLatestPhotosLoading] = useState<boolean>(false);

    const getPersonsLimit = (): number => {
        if (isMobile) {
            return 4;
        }

        if (isTablet) {
            return 6;
        }

        return 10;
    };

    useEffect(() => {
        if (isEventListPending) {
            const page: Page = {
                pageNumber: eventPage,
                pageSize: EVENTS_LIMIT
            };

            const tmpQueryParams = isResetEvents ? {} : queryParams;

            const filter: EventsSearchFilter = {
                is_empty: false,
                ...(tmpQueryParams.location_id && { location_id: queryParams.location_id }),
                ...(tmpQueryParams.event_id && { event_id: queryParams.event_id }),
                ...(tmpQueryParams.year && { year: queryParams.year }),
                ...(chosenPersonID !== null && { person_id: [chosenPersonID] })
            };

            api.events.getEventsList(page, filter)
                .then((resp) => {
                    setIsNextEventPage(!Boolean(resp.data.next === null));
                    setEventList(
                        isEventListMerge
                        ? [...eventList, ...resp.data.results] : resp.data.results
                    );
                })
                .catch(() => {
                    setEventList([]);
                })
                .finally(() => {
                    setIsEventListPending(false);
                    setIsResetEvents(false);
                });
        }
    }, [isEventListPending]);

    useEffect(() => {
        if (isPersonsPending) {
            const payload = registry.form.getPayload();

            const page: Page = {
                pageNumber: personsPage,
                pageSize: getPersonsLimit()
            };
            const filter: PersonsSearchFilter = {
                ...(queryParams.search && { search: queryParams.search }),
                ...(payload.photo && { file_id: payload.photo.id }),
                ...(queryParams.event_id && { event_id: queryParams.event_id })
            };

            api.persons.getPersonsList(page, filter)
                .then((resp) => {
                    setPersons(
                        isPersonsMerge
                            ? [...persons, ...resp.data.results] : resp.data.results
                    );
                    setIsPersonsNext(!Boolean(resp.data.next === null));
                    if (resp.data.count === 1) {
                        setChosenPersonID(resp.data.results[0].id);
                    }
                })
                .finally(() => {
                    setIsPersonsPending(false);
                });
        }
    }, [isPersonsPending]);

    useEffect(() => {
        if (chosenPersonID) {
            setLatestPhotosList([]);
            setIsLatestPhotosLoading(true);
            setEventPage(1);
            setIsEventListMerge(false);
            setIsEventListPending(true);
        } else {
            setLatestPhotosList([]);
            setTotalLatestPhotos(0);
        }
    }, [chosenPersonID]);

    useEffect(() => {
        if (queryParams.file_id || queryParams.search) {
            setChosenPersonID(null);
            setPersons([]);
            setPersonsPage(1);
            setIsPersonsMerge(false);
            setIsPersonsPending(true);
        }
    }, [queryParams.file_id, queryParams.search]);

    useEffect(() => {
        setEventList([]);
        setEventPage(1);
        setIsEventListMerge(false);
        setIsEventListPending(true);
    }, [queryParams.location_id, queryParams.event_id, queryParams.year]);

    useEffect(() => {
        setQueryParams(getNormalizedQuery());
    }, [location.search]);

    useEffect(() => {
        if (isLatestPhotosLoading) {
            const page: Page = {
                pageNumber: 1,
                pageSize: 5
            };

            const filter: PhotosSearchFilter = {
                ...(chosenPersonID && { person_id: [String(chosenPersonID)]})
            };

            api.latestPhotos.getLatestPhotosList(page, filter)
                .then((resp) => {
                    setLatestPhotosList(resp.data.results);
                    setTotalLatestPhotos(resp.data.count);
                    setIsLatestPhotosLoading(false);
                })
                .catch((err) => {
                    console.warn(err);
                    setIsLatestPhotosLoading(false);
                });
        }
    }, [isLatestPhotosLoading]);

    const onFileLoaded = (file: CreateTempFile): void => {
        setImageID(file.id);
    };

    const onChangeFilterForm = debounce(useCallback(() => {
        const payload = registry.form.getPayload();
        const data = {
            ...(queryParams.search && payload.name && { search: queryParams.search }),
            ...(payload.photo && { file_id: payload.photo.id }),
            ...(payload.location && { location_id: payload.location.value }),
            ...(payload.location && { location_name: payload.location.label }),
            ...(payload.event?.value && { event_id: payload.event.value }),
            ...(payload.year?.value && { year: payload.year.value })
        };

        history.replace({
            search: stringify(data, {
                arrayFormat: 'none'
            }),
            state: {
                noScroll: true
            }
        });
    }, [queryParams]), 300);

    const onSubmitFilterForm = debounce(useCallback(() => {
        const payload = registry.form.getPayload();
        const data = {
            ...(payload.photo && { file_id: payload.photo.id }),
            ...(queryParams.file_id && { file_id: queryParams.file_id }),
            ...(payload.name && { search: payload.name }),
            ...(payload.location && { location_id: payload.location.value }),
            ...(payload.location && { location_name: payload.location.label }),
            ...(payload.event?.value && { event_id: payload.event.value }),
            ...(payload.year?.value && { year: payload.year.value })
        };

        history.replace({
            search: stringify(data, {
                arrayFormat: 'none'
            }),
            state: {
                noScroll: true
            }
        });
    }, []), 300);

    const onClickPerson = useCallback((personID: number) => {
        if(chosenPersonID === personID) {
            setChosenPersonID(null);

            return;
        }

        setChosenPersonID(personID);
    }, [chosenPersonID]);

    const $bottomPreviousPosts = useIntersect((entry) => {
        if(entry.isIntersecting) {
            setIsEventListMerge(true);
            setEventPage((prevState) => prevState + 1);
            setIsEventListPending(true);
        }
    }, {
        rootMargin: '500px 0px'
    });

    const elButtonBeforePosts = () => {
        if (eventList.length && !isEventListPending && isNextEventPage) {
            return <Loader ref={$bottomPreviousPosts} />;
        }
    };

    const onReset = () => {
        setChosenPersonID(null);
        setPersons([]);

        setIsResetEvents(true);
        setEventList([]);
        setEventPage(1);
        setIsEventListPending(true);

        registry.form.clearForm();
        window.scrollTo(0, 0);
    };

    const elSidebar = useMemo(() => {
        let defaultLocation = null;

        if (queryParams.location_id && queryParams.location_name) {
            defaultLocation = {
                value: queryParams.location_id,
                label: queryParams.location_name
            };
        }

        let defaultYear = null;

        if (queryParams.year) {
            defaultYear = {
                value: queryParams.year,
                label: queryParams.year
            };
        }

        return (
            <div className={cn('search__sidebar')}>
                <SearchForm
                    onSubmit={onSubmitFilterForm}
                    registry={registry}
                    onChange={onChangeFilterForm}
                    onFileLoaded={onFileLoaded}
                    onReset={onReset}
                    defaultYear={defaultYear}
                    defaultEventID={queryParams.event_id}
                    defaultPersonName={queryParams.search}
                    defaultLocation={defaultLocation}
                />
            </div>
        );
    }, [JSON.stringify(queryParams)]);

    const elContent = useMemo(() => {
        if (!isEventListPending && eventList.length === 0) {
            return (
                <UI.Box padding={true}>
                    <UI.BoxHeader>Ничего не найдено</UI.BoxHeader>
                    <p>Попробуйте изменить условия поиска.</p>
                </UI.Box>
            );
        }

        return (
            <div className={cn('search__results')}>
                {
                    eventList.map((event) => (
                        <Result
                            key={event.id}
                            link={`/events/${event.id}`}
                            photos_count={event.photo_count}
                            place={event.location_name}
                            suit_count={event.sell_suit_count}
                            date={event.date}
                            title={event.name}
                            file_id={imageID}
                            query={`${stringify(getNormalizedQuery())}${chosenPersonID ? `&person_id=${chosenPersonID}` : ''}`}
                            // persons={personList?.map((person) => person.id)}
                            photo_attachments={event.photos}
                        />
                    ))
                }
                {elButtonBeforePosts()}
            </div>
        );
    }, [JSON.stringify(eventList), isEventListPending, isNextEventPage, imageID]);

    const onClickNext = useCallback(() => {
        if (isPersonsNext) {
            setPersonsPage((prevState) => prevState + 1);
            setIsPersonsMerge(true);
            setIsPersonsPending(true);
        }
    }, [isPersonsNext]);

    const elPersons = useMemo(() => {
        if (isPersonsPending && persons.length === 0) {
            return <Loader />;
        }

        if (persons.length) {
            return (
                <Fragment>
                    <UI.Box padding={true} className={cn('search__no-margin-block')}>
                        <PersonCarousel onClickNext={onClickNext} isLoading={isPersonsPending}>
                            {
                                persons.map((item, index) => {
                                    const params = {
                                        item: {
                                            id: item.id,
                                            name: (item.first_name && item.last_name) ? item.full_name : item.id.toString(),
                                            photo_url: item.photo,
                                            is_partner_profile: item.is_partner_profile
                                        },
                                        // onClick: onClickPerson(item.id),
                                        isSelected: Boolean(item.id === chosenPersonID),
                                        isName: true
                                    };

                                    return <CarouselItem key={index} {...params} onClick={() => onClickPerson(item.id)} />;
                                })
                            }
                        </PersonCarousel>
                    </UI.Box>
                    <UI.Box padding={true} className={cn('search__no-margin-block')}>
                        <p><b>Подпишитесь на свою персону и получайте бесплатные уведомления о новых фотографиях. Для подписки перейдите на страницу персоны.</b></p>
                    </UI.Box>
                </Fragment>
            );
        }
    }, [JSON.stringify(persons), isPersonsPending, chosenPersonID]);

    const elLastPhoto = useMemo(() => {
        if (latestPhotosList.length) {
            return (
                <Result
                    title={'Новый контент'}
                    link={'/persons/photos'}
                    photo_attachments={latestPhotosList.map((item) => item.photo_url)}
                    photos_count={totalLatestPhotos}
                    // persons={[Number(id)]}
                    query={`person_id=${chosenPersonID}`}
                />
            );
        } else if (!isLatestPhotosLoading) {
            return (
                <Result
                    title={'Новый контент'}
                    link={'/persons/photos'}
                    className={cn('search-photos__empty')}
                />
            );
        } else {
            return (
                <UI.Box padding={true} className={cn('search-photos__loading')}>
                    <Loader />
                </UI.Box>
            );
        }
    }, [latestPhotosList, totalLatestPhotos]);

    return (
        <UI.Main className={cn('search')}>
            {elSidebar}
            <div className={cn('search__content')}>
                {elPersons}
                {chosenPersonID && elLastPhoto}
                {
                    isEventListPending
                    ? <Loader />
                    : elContent
                }
            </div>
        </UI.Main>
    );
};

export default Search;
